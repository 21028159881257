import {Component, OnInit} from '@angular/core';
import {NavParams, ModalController} from '@ionic/angular';
import {QueryService} from 'src/app/queries/all.query';
import {HttpServiceService} from 'src/app/services/httpService/http-service.service';
import {AlertController} from '@ionic/angular';
import {AramaServiceService} from 'src/app/services/aramaService/arama-service.service';

@Component({
    selector: 'app-yorum',
    templateUrl: './yorum.page.html',
    styleUrls: ['./yorum.page.scss'],
})
export class YorumPage implements OnInit {

    constructor(
        public navParams: NavParams,
        public modalCtrl: ModalController,
        private queryService: QueryService,
        private httpService: HttpServiceService,
        public alertController: AlertController,
        public aramaService: AramaServiceService
    ) {
    }

    survey
    yeniYorumIcerik = ""
    hataMesaji

    ngOnInit() {
        this.survey = this.navParams.get("survey");
        const modalState = {modal: true};
        history.pushState(modalState, null);
    }

    geri() {
        this.modalCtrl.dismiss(this.survey);
    }

    yorumDegisti() {
        this.hataMesaji = null;
    }

    yorumYap() {
        let yeniYorum = this.yeniYorumIcerik.trim();
        if (yeniYorum.length > 120) {
            this.hataMesaji = "Yorum Uzunluğu En Fazla 120 Karakter Olabilir."
            return;
        }
        if (yeniYorum == "") {
            this.hataMesaji = "Boş Yorum Gönderemezsiniz."
            return;
        }
        yeniYorum = yeniYorum.replace("\n", "<br/>");
        let tarih = new Date();
        let newComment = {survey_id: this.survey.survey_id, user_comment: yeniYorum, comment_date: tarih.getTime()};

        let q = this.queryService.addNewComment(newComment);
        let dataSync = this.httpService.httpPost("/query/queryService", {"query": q});

        dataSync.then(data => {
            data.subscribe(res => {
                let res1 = res.body["data"].addNewComment;
                if (res1.status) {
                    this.survey.comment_list.push(newComment);
                    this.yorumBasarili();
                } else {
                    this.hataMesaji = res1.error;
                }
            });
        });
    }

    async yorumBasarili() {
        const alert = await this.alertController.create({
            header: 'Teşekkürler',
            message: 'Yorumunuz sisteme kaydedilmiştir. Kalite kontrol ekibimizin incelemesi sonrası size geri dönüş yapılacaktır.',
            buttons: [
                {
                    text: 'Tamam',
                    handler: () => {
                        this.modalCtrl.dismiss(this.survey);
                    }
                }
            ]
        });

        await alert.present();
    }

    editComment(cmt) {

    }

    tarihGoster(trh) {
        trh = parseFloat(trh);
        if (trh < 100000000) {
            trh = trh * 1000;
        }
        var date = new Date(trh);
        var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + " " + date.getHours() + ":" + minute;
    }
}
