import { Component, HostListener } from '@angular/core';

import { Platform, MenuController, NavController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ThemeService } from './services/themeService/theme.service'
import { Router, NavigationStart } from '@angular/router';
import { AutocloseOverlaysService } from './services/autocloseOverlaysService/autoclose-overlays-service.service';
import { Network } from '@ionic-native/network/ngx';
import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      name: 'Proje Değiştir',
      title: 'plus+ score',
      url: '/selectproject',
      icon: 'cog'
    },
    {
      name: 'Ayarlar',
      title: 'plus+ score',
      url: '/list',
      icon: 'cog'
    },
    {
      name: 'Geri Bildirim',
      title: 'plus+ score',
      url: 'help@plussocre.net',
      icon: 'speedometer'
    },
    {
      name: 'Çıkış',
      title: 'plus+ score',
      url: '/logout',
      icon: 'log-out'
    }
  ];

  appConnected = new BehaviorSubject(true);
  egitimlink = "";

  @HostListener('window:popstate')
  onPopState() {
    this.autocloseOverlaysService.trigger();
  }


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private theme: ThemeService,
    public router: Router,
    public autocloseOverlaysService: AutocloseOverlaysService,
    private network: Network,
    public alertController: AlertController,
    private oneSignal: OneSignal
  ) {
    this.initializeApp();


    if (this.platform.is('cordova')) {
      this.setupPush();
    }
    // this.router.events.subscribe((event: any): void => {
    //   if (event instanceof NavigationStart) {
    //     if (event.navigationTrigger === 'popstate') {
    //       this.autocloseOverlaysService.trigger();
    //     }
    //   }
    // });
  }

  setupPush() {
    this.oneSignal.startInit("0dd6fcfb-6d18-4ded-bb11-90b33ceb6cd1", "557259862427");

    this.authenticationService.user_name.subscribe(x => {
      this.oneSignal.removeExternalUserId();
      this.oneSignal.setExternalUserId(x);
    })
    // this.oneSignal.setExternalUserId(this.authenticationService.authToken.value.)

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(data => {
      if (this.authenticationService.selectedProjectId) {
        this.navCtrl.navigateForward("/notifications");
      }
    });

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      //Açılan bildirim tekrardan açılırsa
    });

    this.oneSignal.endInit();
  }

  ngDoCheck() {
    let nToken = this.authenticationService.tokenObj;
    if (nToken["main_color_code"]) {
      let themeSet = {
        primary: "#3880ff",
        secondary: "#0cd1e8",
        tertiary: "#" + nToken["main_color_code"],
        success: "#10dc60",
        warning: "#ffce00",
        danger: "#f04141",
        dark: "#222428",
        medium: "#c0ccda",
        light: "#f4f5f8",
        beyaz: "#ffffff",
        siyah: "#000000"
      }

      this.theme.setTheme(themeSet)
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.authenticationService.authenticationState.subscribe(state => {
        if (state == 0) {
          this.menuCtrl.enable(false);
          this.navCtrl.navigateRoot("/welcome")
        } else if (state == 1) {
          this.menuCtrl.enable(false);
          this.navCtrl.navigateRoot("/selectproject")
        } else {
          this.menuCtrl.enable(false);
          this.navCtrl.navigateRoot("/login")
        }
      });

      this.authenticationService.projectSettings.subscribe(val => {
        let dinamikEgitim = val.find(x => x.config_code == "advertUrl");
        this.egitimlink = dinamikEgitim ? dinamikEgitim.config_value : "";
      });

    });
  }

  ngOnInit() {
    this.appConnected.subscribe(async x => {
      if (!x) {
        await this.showConnectionAlert();
      }
    });
    setInterval(() => { this.networkSubscriber() }, 300);
  }

  networkSubscriber() {
    if (this.appConnected.value != navigator.onLine) {
      this.appConnected.next(navigator.onLine);
    }
  }

  async showConnectionAlert() {
    const alert = await this.alertController.create({
      header: 'Uyarı!',
      message: "İnternet Bağlantısı Bulanamadı. Lütfen İnternet Bağlantınızı Kontrol Edin.",
      backdropDismiss: false,
      buttons: [
        {
          text: 'Tekrar Dene',
          handler: async () => {
            if (this.appConnected.value == false) {
              await this.showConnectionAlert();
            }
          }
        }, {
          text: 'Çıkış Yap',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });

    await alert.present();
  }

  menuOpened() {
    this.theme.masterHeaderVisibility = "hidden";
  }

  menuClosed() {
    this.theme.masterHeaderVisibility = "inherit";
  }
}