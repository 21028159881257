import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import * as Color from 'color';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  masterHeaderVisibility = "inherit";

  setTheme(theme) {
    const cssText = CSSTextGenerator(theme, this.masterHeaderVisibility);
    this.setGlobalCss(cssText);
  }

  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }
  contrast(color, ratio = 0.8) {
    color = Color(color);
    return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
  }

  private setGlobalCss(cssText: string) {
    this.document.documentElement.style.cssText = cssText;
  }

}
const defaults = {
  primary: "#3880ff",
  secondary: "#0cd1e8",
  tertiary: "#7044ff",
  success: "#10dc60",
  warning: "#ffce00",
  danger: "#f04141",
  dark: "#222428",
  medium: "#c0ccda",
  light: "#f4f5f8",
  beyaz: "#ffffff",
  siyah: "#000000"
};
function CSSTextGenerator(colors, masterHeaderVisibility) {
  colors = { ...defaults, ...colors };

  const {
    primary,
    secondary,
    tertiary,
    success,
    warning,
    danger,
    dark,
    medium,
    light,
    beyaz,
    siyah
  } = colors;

  const shadeRatio = 0.1;
  const tintRatio = 0.1;

  return `
  --ion-color-base: ${light};
  --ion-color-contrast: ${dark};
  --ion-backgroud-color: ${light};
  --ion-text-color: ${dark};
  --ion-toolbar-backgroud-color: #FFFFFF;
  --ion-toolbar-text-color: #000000;
  --ion-item-backgroud-color: ${contrast(light, 0.3)};
  --ion-item-text-color: ${contrast(dark, 0.3)};

  --ion-color-tertiary: ${tertiary};
  --ion-color-tertiary-rgb: 56,128,255;
  --ion-color-tertiary-contrast: ${contrast(tertiary, 1)};
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade:  ${Color(tertiary).darken(shadeRatio)};
  --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
  --ion-master-header-visibility: ${masterHeaderVisibility};
  `;
}

function contrast(color, ratio = 0.8) {
  color = Color(color);
  return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
}