import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, NavController } from '@ionic/angular';
import { YorumPage } from '../../list/yorum/yorum.page'
import { QueryService } from 'src/app/queries/all.query';
import { HttpServiceService } from 'src/app/services/httpService/http-service.service';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AramaServiceService } from 'src/app/services/aramaService/arama-service.service';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.page.html',
  styleUrls: ['./notification-detail.page.scss'],
})
export class NotificationDetailPage implements OnInit {

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private navCtrl: NavController,
    private queryService: QueryService,
    private httpService: HttpServiceService,
    private authenticationService: AuthenticationService,
    public aramaService:AramaServiceService
  ) { }

  not
  ngOnInit() {
    this.not = this.navParams.get("not");
    const modalState = { modal: true };
    history.pushState(modalState, null);
    this.setStatus(this.not, 1);
  }

  geri() {
    window.history.back();
    this.modalCtrl.dismiss(this.not);
  }

  sayfayiAc() {
    this.navCtrl.navigateForward("/singlesurvey/" + this.not.entity_id);
    this.modalCtrl.dismiss(this.not);
  }
  
  async setStatus(not, status){    
    let q = this.queryService.setNotificationStatus(not.notification_id, status);
    let dataSync = this.httpService.httpPost("/query/queryService", { "query": q });

    dataSync.then(data => {
      data.subscribe(res => {
        this.authenticationService.notificationsCount.next(res.body["data"].changeNotificationStatus.error);
      });
    });
  }

}
