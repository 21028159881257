import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter-select-detail',
  templateUrl: './filter-select-detail.page.html',
  styleUrls: ['./filter-select-detail.page.scss'],
})
export class FilterSelectDetailPage implements OnInit {

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController
  ) { }

  filterCriteria
  selectedValues
  ngOnInit() {
    this.filterCriteria = this.navParams.get("filterCriteria");
    if (this.filterCriteria.selectedValues) {
      this.selectedValues = JSON.parse(JSON.stringify(this.filterCriteria.selectedValues));
    } else {
      this.selectedValues = [];
    }
  }


  seciliMi(deger) {
    if (this.selectedValues && this.selectedValues.indexOf(deger) != -1) {
      return true;
    } else {
      return false;
    }
  }

  filtreUygula() {
    this.filterCriteria.selectedValues = this.selectedValues;
    this.modalCtrl.dismiss(this.filterCriteria);
  }

  vazgec() {
    this.modalCtrl.dismiss(this.filterCriteria);
  }

  checkDegisti(e, deger) {
    if (!this.selectedValues) {
      this.selectedValues = [];
    }
    if (e.detail.checked) {
      if (this.selectedValues.indexOf(deger) == -1) {
        this.selectedValues.push(deger);
      }
    } else {
      let degerIndex = this.selectedValues.indexOf(deger);
      if (degerIndex !== -1) {
        this.selectedValues.splice(degerIndex, 1);
      }
    }
  }
}
