import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { share, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Platform, LoadingController } from '@ionic/angular';
import { AuthenticationService } from '../authentication/authentication.service';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  constructor(public http: HttpClient, private nativeHttp: HTTP, private rooter: Router, private plt: Platform, public authService: AuthenticationService, private loadingCtrl: LoadingController) {
    this.plt.ready().then(() => {
      this.authService.authToken.subscribe(x => {
        this.authToken = x;
      })
    });
  }
  authToken = "";
  //serverUrl = "http://localhost:8080/"
  serverUrl = "https://api.plusscore.net/"

  async httpPost(Geturl: string, Postdata: any) {
    let loading = await this.loadingCtrl.create();
    await loading.present();
    var url = Geturl;
    let data: Observable<HttpResponse<Object>>;
    let postData = Postdata;
    let headers: HttpHeaders
    if (this.authToken && this.authToken != "") {
      headers = new HttpHeaders().set("Authorization", "Token " + this.authToken);
    }
    data = this.http.post(this.serverUrl + 'api' + url, postData, { headers: headers, observe: 'response' }).pipe(share());

    data.subscribe(result => {
      if (result.headers && result.headers.get("token")) {
        let newToken = result.headers.get("token");
        this.authService.authToken.next(newToken);
      }
      if (result.body["auth"] != undefined && !result.body["auth"]) {
        this.rooter.navigate(['/login']);
      }
      loading.dismiss();
    })
    return data;
  }

  httpPostWithoutLoading(Geturl: string, Postdata: any) {
    var url = Geturl;
    let data: Observable<HttpResponse<Object>>;
    let postData = Postdata;
    let headers: HttpHeaders
    if (this.authToken && this.authToken != "") {
      headers = new HttpHeaders().set("Authorization", "Token " + this.authToken);
    }
    data = this.http.post(this.serverUrl + 'api' + url, postData, { headers: headers, observe: 'response' }).pipe(share());

    data.subscribe(result => {
      if (result.headers && result.headers.get("token")) {
        let newToken = result.headers.get("token");
        this.authService.authToken.next(newToken);
      }
      if (result.body["auth"] != undefined && !result.body["auth"]) {
        this.rooter.navigate(['/login']);
      }
    })
    return data;
  }
  // async httpPost(Geturl: string, Postdata: any) {
  //   let loading = await this.loadingCtrl.create();
  //   await loading.present();
  //   var url = Geturl;
  //   let data: Observable<HttpResponse<Object>>;
  //   let postData = Postdata;
  //   let headers;
  //   headers = { 'Content-Type': 'application/json' };
  //   if (this.authToken && this.authToken != "") {
  //     headers = { "Content-Type": "application/json", "Authorization": "Token " + this.authToken };
  //   }

  //   this.nativeHttp.setDataSerializer("json");
  //   return from(this.nativeHttp.post(this.serverUrl + 'api' + url, postData, headers)).pipe(
  //     finalize(() => loading.dismiss())
  //   );
  // }
}
