import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';


const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(0);
  authToken = new BehaviorSubject("");
  selectedProjectId = 0;
  selectedProjectName = "";
  notificationsCount = new BehaviorSubject("");
  tokenObj = {};
  projectSettings = new BehaviorSubject([]);
  appMediaPath
  surveyMediaPath
  user_name = new BehaviorSubject("");

  constructor(private storage: Storage, private plt: Platform, private authService: AuthenticationService) {
    this.plt.ready().then(() => {
      this.checkToken();
    });

    this.authToken.subscribe(tkn => {
      if (tkn.split(".").length > 1) {
        let nToken = decodeURIComponent(atob(tkn.split(".")[1]).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        nToken = JSON.parse(nToken)
        this.tokenObj = nToken;
        this.appMediaPath = nToken["config"].find(x => x.code == "APPMEDIAURL").value;
        this.surveyMediaPath = nToken["config"].find(x => x.code == "SURVEYMEDIAURL").value;
        if (this.user_name.value != nToken["user_name"]) {
          this.user_name.next(nToken["user_name"]);
        }
      }
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authToken.next(res);
        this.authenticationState.next(1);
      } else {
        this.authenticationState.next(2);
      }
    })
  }

  login(tokenKey, loginmi) {
    return this.storage.set(TOKEN_KEY, tokenKey).then(() => {
      this.authToken.next(tokenKey);
      this.authenticationState.next(loginmi);
    });
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(2);
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }
}
