import { Component, OnInit } from '@angular/core';
import { QueryService } from 'src/app/queries/all.query';
import { ModalController } from '@ionic/angular';
import { Animation } from '@ionic/core'
import { FilterSelectDetailPage } from './filter-select-detail/filter-select-detail.page'

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.page.html',
  styleUrls: ['./filter-modal.page.scss'],
})
export class FilterModalPage implements OnInit {

  constructor(
    private queryService: QueryService,
    public modalCtrl: ModalController
  ) { }
  filterCriterias
  ngOnInit() {
    this.filterCriterias = JSON.parse(JSON.stringify(this.queryService.filterCriterias.value));
  }

  filtreUygula(){
    this.queryService.filterCriterias.next(this.filterCriterias);
    this.modalCtrl.dismiss();
  }

  async selectFilterCriteria(filterCriteria){
    const mdl = await this.modalCtrl.create({
      component: FilterSelectDetailPage,
      cssClass: 'half-modal',
      animated: true,
      enterAnimation: myEnterAnimation,
      leaveAnimation: myLeaveAnimation,
      componentProps: {
        filterCriteria: filterCriteria
      }
    });

    mdl.onDidDismiss()
      .then((data) => {
        filterCriteria = data['data'];
      });

    mdl.present();
  }

}


function myEnterAnimation(AnimationC: Animation, baseEl: HTMLElement): Promise<Animation> {

  const baseAnimation = new AnimationC();

  const backdropAnimation = new AnimationC();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  const wrapperAnimation = new AnimationC();
  wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

  wrapperAnimation.beforeStyles({ 'opacity': 1 })
    .fromTo('translateX', '100%', '0%');

  backdropAnimation.fromTo('opacity', 0.01, 0.4);

  return Promise.resolve(baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(400)
    .beforeAddClass('show-modal')
    .add(backdropAnimation)
    .add(wrapperAnimation));

}

function myLeaveAnimation(AnimationC: Animation, baseEl: HTMLElement): Promise<Animation> {

  const baseAnimation = new AnimationC();

  const backdropAnimation = new AnimationC();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  const wrapperAnimation = new AnimationC();
  const wrapperEl = baseEl.querySelector('.modal-wrapper');
  wrapperAnimation.addElement(wrapperEl);
  const wrapperElRect = wrapperEl!.getBoundingClientRect();

  wrapperAnimation.beforeStyles({ 'opacity': 1 })
                  .fromTo('translateX', '0%', `${window.innerWidth - wrapperElRect.left}px`);

  backdropAnimation.fromTo('opacity', 0.4, 0.0);

  return Promise.resolve(baseAnimation
    .addElement(baseEl)
    .easing('ease-out')
    .duration(250)
    .add(backdropAnimation)
    .add(wrapperAnimation));

}