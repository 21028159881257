import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class QueryService {
  constructor(
    private authService: AuthenticationService
  ) { }
  searchFilter = new BehaviorSubject(null);
  filterCriterias = new BehaviorSubject(null);

  private getFilterCriteriasSelected() {
    let selectedFilterCriterias = [];
    let filters = this.filterCriterias.value;
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        const filterObj = filters[i];
        if (filterObj.selectedValues && filterObj.selectedValues.length > 0) {
          selectedFilterCriterias.push({
            table_name: filterObj.table_name,
            column_name: filterObj.column_name,
            column_type: filterObj.column_type,
            filter_sql: filterObj.filter_sql,
            selectedValues: filterObj.selectedValues
          });
        }
      }
    }

    return JSON.stringify(selectedFilterCriterias).replace(/\\/g, "\\\\").replace(/\"/g, "\\\"");
  }

  public getStoreFeeds(sortField, offset, entity_id = null) {
    var qStr = [];
    qStr.push("projectId:" + this.authService.selectedProjectId);
    qStr.push("sortField:" + sortField);
    qStr.push("offset:" + offset);
    if (entity_id) {
      qStr.push("entity_id:" + entity_id)
    } else {
      this.searchFilter.value ? qStr.push("entity_id:" + this.searchFilter.value.entity_id) : "";
    }
    qStr.push('filterCriterias:"' + this.getFilterCriteriasSelected() + '"');

    return `query{
            storeFeeds(`+ qStr.join(", ") + `) {
              survey_id
              entity_name
              total_kpi_value
              survey_date
              entity_id
              media_list {
                survey_id
                media_path
                media_file
              }
              kpi_list{
                dscategory
                dsmetric1
                kpi_comment
              }
              comment_list {
                survey_id
                user_comment
                comment_date
                cuser
              }
            }
          }`;
  }

  public addNewComment(newComment) {
    return `mutation{
        addNewComment(projectId:` + this.authService.selectedProjectId + `survey_id:` + newComment.survey_id + `, user_comment:"` + newComment.user_comment.replace("\"", "\\\"") + `") {
          status
          error
        }
      }`;
  }
  public getNotifications(offset, entity_id = null) {
    var qStr = [];
    qStr.push("projectId:" + this.authService.selectedProjectId);
    qStr.push("offset:" + offset);
    if (entity_id) {
      qStr.push("entity_id:" + entity_id)
    } else {
      this.searchFilter.value ? qStr.push("entity_id:" + this.searchFilter.value.entity_id) : "";
    }
    qStr.push('filterCriterias:"' + this.getFilterCriteriasSelected() + '"');
    return `query{
          notifications(`+ qStr.join(", ") + `){
            entity_id
            notification_id
            survey_id
            entity_name
            message_content_type
            message_header
            message_content
            publish_date
            message_status
          }
        }`;
  }
  public getNotificationCount() {
    return `query{
          notificationsCount(projectId:` + this.authService.selectedProjectId + `){
            status
            error
          }
        }`;
  }

  public setNotificationStatus(notification_id, status) {
    return `mutation{
        changeNotificationStatus(projectId:` + this.authService.selectedProjectId + `, notification_id:` + notification_id + `status:` + status + `) {
          status
          error
        }
      }`;
  }

  public getCoordinates(entity_name = null, entity_id = null) {
    let filter = ', filterCriterias:"' + this.getFilterCriteriasSelected() + '"';
    if (entity_name) {
      filter = ', entity_name:"' + entity_name + '"';
    }
    if (entity_id) {
      filter = ', entity_id:' + entity_id + '';
    }
    return `query{
          mapCoordinates(projectId:` + this.authService.selectedProjectId + filter + ` ){
            entity_id
            entity_name
            lattitude
            longtitude
          }
        }`;
  }

  public getFilterCriterias() {
    return `query{          
      filterCriterias(projectId:` + this.authService.selectedProjectId + `){            
        table_name            
        column_name   
        column_type        
        column_header      
        filter_sql  
        values  
      }
    }`;
  }

  public getProjectSettings() {
    return `query{          
      projectSettings(projectId:` + this.authService.selectedProjectId + `){            
        config_code
        config_value
      }
    }`;
  }

  public getDashboard() {
    let filter = ', filterCriterias:"' + this.getFilterCriteriasSelected() + '"';
    return `query{          
      dashboardView(projectId:` + this.authService.selectedProjectId + ` ){            
        title
        tooltip
        type
        sort_number
        width_percent
        value(projectId:` + this.authService.selectedProjectId + filter + ` )
       }        
      }`
  }
}