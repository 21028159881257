import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { YorumPageModule } from './list/yorum/yorum.module'
import { FilterModalPageModule } from './components/filter-modal/filter-modal.module'
import { FilterSelectDetailPageModule } from './components/filter-modal/filter-select-detail/filter-select-detail.module'
import { NotificationDetailPageModule } from './notifications/notification-detail/notification-detail.module'
import { ForgotpasswordPageModule } from './login/forgotpassword/forgotpassword.module'

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HttpClient } from "@angular/common/http"
import { HTTP } from '@ionic-native/http/ngx';
import { Network } from '@ionic-native/network/ngx'
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { OneSignal } from '@ionic-native/onesignal/ngx'

import { File } from "@ionic-native/file/ngx"
import { SocialSharing } from "@ionic-native/social-sharing/ngx"

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
library.add(fas, far)

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    YorumPageModule,
    FilterModalPageModule,
    FilterSelectDetailPageModule,
    NotificationDetailPageModule,
    ForgotpasswordPageModule,
    AutoCompleteModule,
    FontAwesomeModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    Network,
    File,
    SocialSharing,
    OneSignal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
