import { Injectable } from '@angular/core';
import { QueryService } from 'src/app/queries/all.query';
import { HttpServiceService } from '../httpService/http-service.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AramaServiceService {
  labelAttribute = 'entity_name';
  aramaGorunsun = false;
  aramaBilgisi = "";
  constructor(
    private queryService: QueryService,
    public httpService: HttpServiceService,
  ) { }
  searchDegisti(seciliItem){
    this.queryService.searchFilter.next(seciliItem);
  }

  getResults(key) {
    if(key.trim() == "" || key.trim().length < 3){
      return [];
    }
    let q = this.queryService.getCoordinates(key.trim());
    return this.httpService.httpPostWithoutLoading("/query/queryService", { "query": q }).pipe(map(
      (result) => {
         return result.body["data"].mapCoordinates.slice(0, 5);
      }
   ));

    // return data.subscribe(res => {
    //   return res.body["data"].storeFeeds;
      // let result = [];
      // for (let i = 0; i < resArray.length; i++) {
      //   const element = resArray[i];
      //   result.push(element.entity_name);
      // }
      // console.log(result)
      // return result;
    // });
  }
}
