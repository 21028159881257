import { Component, OnInit } from '@angular/core';
import { HttpServiceService } from '../../services/httpService/http-service.service'
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.page.html',
  styleUrls: ['../login.page.scss'],
})
export class ForgotpasswordPage implements OnInit {

  constructor(
    private httpService: HttpServiceService,
    public modalCtrl: ModalController,
    public alertController: AlertController) { }

  ngOnInit() {
  }
  userName
  submitError
  refreshPassword(form) {
    let forgotModel = { "eMail": form.value.username, "userName": form.value.username };
    let dataSync = this.httpService.httpPost("/user/loginforgot", forgotModel);

    dataSync.then(data => {
      data.subscribe(async res => {
        let status = true;
        if (res.body["Netice"] == "Hata!") {
          this.submitError = res.body["Mesaj"];
          status = false;
        }

        if (status) {

          const alert = await this.alertController.create({
            header: 'Teşekkürler',
            message: res.body["Kayitlar"]["mesaj"],
            buttons: [
              {
                text: 'Tamam',
                handler: () => {
                  this.modalCtrl.dismiss();
                }
              }
            ]
          });

          await alert.present();
        } else {
        }
      });
    });
  }

  vazgec() {
    this.modalCtrl.dismiss();
  }
}
